// Contact.jsx
import React from 'react';
import './Contact.css';
import { GrContact } from "react-icons/gr";

const Contact = () => {
  return (
    <div className="contact-container">
      <div className='top_contact'>
        <GrContact />
        <h1>Contact Information</h1>
      </div>
      <div className="contact-details">
        <p>
          <strong>Name:</strong> REHABILITATION MINISTRY EMPLOYEES COOPERATIVE HOUSE BUILDING SOCIETY LTD.
        </p>
        <p>
          <strong>Registration No.:</strong> 2007 dt. 20-10-1959
        </p>
        <p>
          <strong>Address:</strong> Society Office, near House No.A-28, Block-A, Shivalik, New Delhi-110017
        </p>
        <p>
          <strong>Telephone:</strong> 01141717001
        </p>
        <p>
          <strong>Email:</strong> rmeshivalik@gmail.com
        </p>
      </div>
    </div>
  );
};

export default Contact;
