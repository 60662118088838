// Data.jsx
import React from 'react';
import './Data.css'; // Import your CSS file for styling

const pdfData = [
  { name: 'Annual General Meeting proceeding 6-12-2022', file: 'https://mega.nz/file/cHtFFTLY#oab5cx3S1RfUJqWRFP7vpjsyhoyvsAJ2uUpRt9FuJ0w', category: 'Annual General Meeting Report' },
  { name: 'Annual General Meeting proceeding 29-10-2023', file: 'https://mega.nz/file/JDsDwZrI#Y6LchmB-chhUkLNsCVY8ZSWD5hINe1aas9K6QJq0wL8', category: 'Annual General Meeting Report' },
  { name: 'Annual General Meeting Resolutions', file: 'https://mega.nz/file/IW02nQ4Q#X__d1gle7ddyLqVq5fctKoiFxyaI5Mv7GJTEnzp0Jac', category: 'Annual General Meeting Report' },
  { name: 'Annual return 2020-21', file: 'https://mega.nz/file/kbUyVBZJ#XzgcqYEjsrxLII7Hb4jenwC1d_357VCGEwNV-xP4bRY', category: 'Annual Return' },
  { name: 'Annual return 2021-22', file: 'https://mega.nz/file/NP8R1Q6b#oq8sB0zuL-q__LYUjt2J-fT6zTUmNLgQcpC_nfe5fz0', category: 'Annual Return' },
  { name: 'As on 31-07-22 Member ship details', file: 'https://mega.nz/file/tXVVDAQD#3zhueOnuayRn6x-hHnd1290-arIkMKsZ2I4x9hFZ6yY', category: 'Membership Details' },
  { name: 'Audit Report 2021-22', file: 'https://mega.nz/file/1O1WXBhL#d4OwuB0STe5OaC1J9rrYysiowjDFb62Y_aVXZj2sLBw', category: 'Audit Report' },
  { name: 'Audit Report 2022-23', file: 'https://mega.nz/file/8WU0HaYb#JHrh7lmSwe1CDcCEN7HIqNaF4pwDV98OTFoGgWrwcaY', category: 'Audit Report' },
  { name: 'Dec.2021 Election & MC detail', file: 'https://mega.nz/file/FP8WmDSK#IOY27nRYm5l3coLw3If_-K12sEXvBMy4uTemeToUlUg', category: 'Annual General Meeting Report' },
  { name: 'Notice for Annual General Meeting 29-10-2023', file: 'https://mega.nz/file/dbMBjYjY#I4MQwzMFSvC_MNZmsrxJDWSmVvRtNGr7fsSU1sqX3Vk', category: 'Notices' },
  { name: 'Public notices', file: 'https://mega.nz/file/tW1F0QbL#IHp5DTGAiwAwDqteIhUmFCI5oalgO32nW7mFGcigiVE', category: 'Notices' },
  { name: 'Resolutions of MC', file: 'https://mega.nz/file/ZLk1lJoD#roabIp195hYkGpk7dSKAl1xV9N-o_fsVj7n7Ke20nIg', category: 'Resolutions' },

];

const Data = () => {
  // Group PDFs based on categories
  const groupedPdfData = pdfData.reduce((acc, pdf) => {
    if (!acc[pdf.category]) {
      acc[pdf.category] = [];
    }
    acc[pdf.category].push(pdf);
    return acc;
  }, {});

  return (
    <div className="data-container">
      <div className="topbar_pdf">
        <h1>PDF/s</h1>
      </div>
      {Object.keys(groupedPdfData).map((category, index) => (
        <div key={index} className="pdf-section">
      <div className="topbar_pdf_new">

          <h2>{category}</h2></div>
          <div className="pdf-list">
            {groupedPdfData[category].map((pdf, pdfIndex) => (
              <div key={pdfIndex} className="pdf-item">
                <a href={`${pdf.file}`} target="_blank" rel="noopener noreferrer">
                  <img src="/pdf.png" alt="PDF Icon" />
                </a>
                <p>{pdf.name}</p>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Data;
