import React from 'react';
import './Pages.css';
import { NavLink } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Home = () => {
  const foundationImages = [
    "/images/Gallery/60thFoundation/A33a0025.jpg",
    "/images/Gallery/60thFoundation/A33a0188.jpg",
    "/images/Gallery/60thFoundation/A33a0236.jpg",
    "/images/Gallery/60thFoundation/A33a0238.jpg",
    "/images/Gallery/60thFoundation/A33a0252.jpg",
    "/images/Gallery/60thFoundation/A33a0271.jpg",
    "/images/Gallery/60thFoundation/A33a0314.jpg",
    "/images/Gallery/60thFoundation/A33a0330.jpg",
    "/images/Gallery/60thFoundation/A33a0356.jpg",
  ];
  const foundationImages2 = [
    "/images/Gallery/60thFoundation/10.jpg",
    "/images/Gallery/60thFoundation/11.jpg",
    "/images/Gallery/60thFoundation/12.jpg",
    "/images/Gallery/60thFoundation/13.jpg",
    "/images/Gallery/60thFoundation/14.jpg",
    "/images/Gallery/60thFoundation/15.jpg",
  ];
  const foundationImages3 = [
    "/images/Gallery/60thFoundation/16.jpg",
    "/images/Gallery/60thFoundation/17.jpg",
    "/images/Gallery/60thFoundation/18.jpg",
    "/images/Gallery/60thFoundation/19.jpg",
    "/images/Gallery/60thFoundation/20.jpg",
    "/images/Gallery/60thFoundation/21.jpg",
  ];
  const SocietyImages = [
    "/images/Gallery/Society/1.jpg",
    "/images/Gallery/Society/2.jpg",
    "/images/Gallery/Society/3.jpg",
    "/images/Gallery/Society/4.jpg",
    "/images/Gallery/Society/5.jpg",
    "/images/Gallery/Society/6.jpg",
    "/images/Gallery/Society/7.jpg",
    "/images/Gallery/Society/8.jpg",
    "/images/Gallery/Society/9.jpg",
    "/images/Gallery/Society/10.jpg",
    "/images/Gallery/Society/11.jpg",
    "/images/Gallery/Society/12.jpg",
    "/images/Gallery/Society/13.jpg",
    "/images/Gallery/Society/14.jpg",
    "/images/Gallery/Society/15.jpg",
    "/images/Gallery/Society/16.jpg",
  ];
  const Meeting = [
    "/images/Gallery/Meeting/1.jpg",
    "/images/Gallery/Meeting/2.jpg",
    "/images/Gallery/Meeting/3.jpg",
    "/images/Gallery/Meeting/4.jpg",
    "/images/Gallery/Meeting/5.jpg",
    "/images/Gallery/Meeting/6.jpg",
    "/images/Gallery/Meeting/7.jpg",
    // "/images/Gallery/Meeting/8.jpg",
    "/images/Gallery/Meeting/9.jpg",
    "/images/Gallery/Meeting/10.jpg",
  ]
  const NewYear = [
    "/images/Gallery/NewYear/1.jpeg",
    "/images/Gallery/NewYear/2.jpeg",
    "/images/Gallery/NewYear/3.jpeg",
    "/images/Gallery/NewYear/4.jpeg",
  ]

  const medicalCampImages = [
    "/images/Gallery/MedicalCamp/1.jpeg",
    "/images/Gallery/MedicalCamp/2.jpeg",
    "/images/Gallery/MedicalCamp/3.jpeg",
    "/images/Gallery/MedicalCamp/4.jpeg",
    "/images/Gallery/MedicalCamp/5.jpeg",
    "/images/Gallery/MedicalCamp/6.jpeg",
    "/images/Gallery/MedicalCamp/7.jpeg",
    "/images/Gallery/MedicalCamp/8.jpeg",
    "/images/Gallery/MedicalCamp/9.jpeg",
    "/images/Gallery/MedicalCamp/10.jpeg",
    "/images/Gallery/MedicalCamp/11.jpeg",
    "/images/Gallery/MedicalCamp/12.jpeg",
    "/images/Gallery/MedicalCamp/13.jpeg",
    "/images/Gallery/MedicalCamp/14.jpeg",
    "/images/Gallery/MedicalCamp/15.jpeg",
    "/images/Gallery/MedicalCamp/16.jpeg",
    "/images/Gallery/MedicalCamp/17.jpeg",
    "/images/Gallery/MedicalCamp/18.jpeg",
    "/images/Gallery/MedicalCamp/19.jpeg",
    "/images/Gallery/MedicalCamp/20.jpeg",
    "/images/Gallery/MedicalCamp/21.jpeg",
    "/images/Gallery/MedicalCamp/22.jpeg",

  ];
  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <div className="home">
      <div className="banner fade-in">
        <h2>REHABILITATION MINISTRY EMPLOYEES CO-OPERATIVE
          HOUSE BUILDING SOCIETY LTD.
        </h2>
        <h1>
          SHIVALIK
        </h1>
        <p className='specific_location'>Malviya Nagar, Delhi</p>
        <NavLink to="/data" className='nolink'>
          Know More
        </NavLink>
      </div>
      <div id="gallery">
        <div className="sub_gallery">
        <h2>Society's Images</h2>
          <div>
            <Slider {...sliderSettings}>
              {SocietyImages?.map((image, index) => (
                <div key={index}>
                  <img src={image} className='slider-img' alt={`Foundation Image ${index + 1}`} />
                </div>
              ))}
            </Slider>
          </div>

          <h2>Medical Camp on 7-4-2024</h2>
          <div>
            <Slider {...sliderSettings}>
              {medicalCampImages.map((image, index) => (
                <div key={index} className='image_container'>
                  <img src={image} className='slider-img' alt={`Medical camp Image ${index + 1}`} />
                </div>
              ))}
            </Slider>
          </div>


          <h2>60th Foundation celebrated on 1-12-2019</h2>
          <div>
            <Slider {...sliderSettings}>
              {foundationImages.map((image, index) => (
                <div key={index}>
                  <img src={image} className='slider-img' alt={`Foundation Image ${index + 1}`} />
                </div>
              ))}
            </Slider>
          </div>
          <div>
            <Slider {...sliderSettings}>
              {foundationImages2.map((image, index) => (
                <div key={index}>
                  <img src={image} className='slider-img' alt={`Foundation Image ${index + 1}`} />
                </div>
              ))}
            </Slider>
          </div>
          <div>
            <Slider {...sliderSettings}>
              {foundationImages3.map((image, index) => (
                <div key={index}>
                  <img src={image} className='slider-img' alt={`Foundation Image ${index + 1}`} />
                </div>
              ))}
            </Slider>
          </div>
          <h2>General Body meetings</h2>
          <div>
            <Slider {...sliderSettings}>
              {Meeting?.map((image, index) => (
                <div key={index}>
                  <img src={image} className='slider-img' alt={`Meeting Image ${index + 1}`} />
                </div>
              ))}
            </Slider>
          </div>
          <h2>New Year 2023 Celebration</h2>
          <div>
            <Slider {...sliderSettings}>
              {NewYear?.map((image, index) => (
                <div key={index}>
                  <img src={image} className='slider-img' alt={`New Year Image ${index + 1}`} />
                </div>
              ))}
            </Slider>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Home;
