import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { GiHamburgerMenu } from 'react-icons/gi';
import './Navbar.css';

const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const handleNavLinkClick = () => {
    setShowNavbar(false);
  };

  return (
    <nav className="navbar">
      <div className="container">
        <div ><NavLink to="/" className="logo" >
          SHIVALIK
        </NavLink></div>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <GiHamburgerMenu />
        </div>
        <div className={`nav-elements ${showNavbar && 'active'}`}>
          <ul>
            <li>
              <NavLink to="/" onClick={handleNavLinkClick}>
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/members" onClick={handleNavLinkClick}>
                Members
              </NavLink>
            </li>
            <li>
              <NavLink to="/data" onClick={handleNavLinkClick}>
                Data PDFs
              </NavLink>
            </li>
            {/* <li>
              <NavLink to="/about" onClick={handleNavLinkClick}>
                About
              </NavLink>
            </li> */}
            <li>
              <NavLink to="/contact" onClick={handleNavLinkClick}>
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
