import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './Pages/Home';
import Members from './Pages/Members';
import Data from './Pages/Data';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Popup from './Components/Popup';

const App = () => {
    const [showPopup, setShowPopup] = useState(true);
    const [showStickyBar, setShowStickyBar] = useState(false);

    const handleClosePopup = () => {
        setShowPopup(false);
        setShowStickyBar(true);
    };

    const handleOpenPopup = () => {
        setShowPopup(true);
        setShowStickyBar(false);
    };

    return (
        <div className='Main'>
            {/* {showPopup && <Popup onClose={handleClosePopup} />}
            {showStickyBar && (
                <div className="sticky-bar" onClick={handleOpenPopup}>
                    <p>! Important !</p>
                </div>
            )} */}
            <Routes>
                <Route path="/" exact element={<Home />} />
                <Route path="/members" element={<Members />} />
                <Route path="/data" element={<Data />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
            </Routes>
        </div>
    );
};

export default App;
