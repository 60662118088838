import React from 'react'
import './Footer.css'
const Footer = () => {
    return (
        <div className="footer-container">
            <p className="copyright-text">© 2024 SHIVALIK, Malviya Nagar, Delhi</p>
        </div>
    )
}

export default Footer